.containerFooter{
    background-color: #274373; 
    padding-bottom: 0.5em;
}

.barra-div {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  width: 100%;
  margin-bottom: 20px;
   }

.footerName{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 50px;
    margin-right: 50px;
}


.footer{
    font-size: 1em;
    font-family: 'Poppins', sans-serif;
    color: #dddddd;
}