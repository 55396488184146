/* .carousel-img{
 height: 45em; 
   max-width: 100%; 
}    */

/*  @media (min-width: 768px) { .carousel-img { width: 20em; } }
@media (min-width: 992px) { .carousel-img { width: 1030px; } }
@media (min-width: 1200px) { .carousel-img { width: 1830px; } }  */

@media screen and (max-width: 465px) {.carousel-img{height: 15em;}} 
@media (min-width: 466px) and (max-width: 768px) {.carousel-img{height: 20em;}}
@media (min-width: 769px) and (max-width: 1200px) {.carousel-img{height: 25em;}}
@media (min-width: 1201px) {.carousel-img{height: 45em;}}

/* @media screen and (max-width: 1200px) {.carousel-img{height: 25em;}}
@media screen and (max-width: 1500px) {.carousel-img{height: 30em;}} */

.carousel-flecha{
    opacity: 1; width: 50px; height: 65px;  background-color: rgba(51, 51, 51, 0.9); font-size: 36px; line-height: 46px; text-align: center; border-radius: 10px;  color: #dddddd; display: flex; align-items: center; justify-content: center;
}

.carousel-flecha:hover{
    outline: 0; color: #fff; text-decoration: none; opacity: 0.9; filter: alpha(opacity=90);
}

.btn-carousel{
    opacity: 0.8;
    width: 5em;
}

.btn-slider{
    color: #dddddd;
   
}

.btn-slider:hover{
    color: #fff;
    text-decoration: none;
}



/* @media (min-width: 992px) {.carousel-container{margin-top: -20em; }}
@media (min-width: 992px) {.carousel-container{margin-top: -20em; }}
@media (min-width: 880px) {.carousel-container{margin-top: -19em; }}
 */

.tit-slider{
    font-size: 0.8em;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: bolder;
    color: #dddddd;
 
}

.subtit-slider{
    font-size: 0.7em;
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    font-weight: bolder;
    color: #dddddd;
}

.panel-grid .widget-title {
    font-family: "Montserrat", Helvetica, Arial, sans-serif;
    text-align: left;
     font-size: 0.8em; 
     margin-top: 0; }
