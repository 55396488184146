.body-plan{
    color: #424242;
    font-family: 'Poppins', sans-serif;
}

.img-plan{
    width: 10em;
    height: 9em;
}

.body-title{
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    padding-bottom: 1em;
}

.body-text{
    font-size: 0.9rem;
    text-align: left;
    
    ul{
        list-style:none;
        
        li{
            padding: 0.2em;
        }
          
}
}

.badge-plan{
    font-family: 'Poppins', sans-serif;
    font-size: 1.5rem;
    width: 50%;
    margin:0 auto;
    margin-top: -1.5em;
    position:relative;
    top: 15px;
}

.footer-plan{
    background-color: #274373;
}
.footer-price{
    color: white;
    font-weight: bolder;
    letter-spacing: 1px;
}

.img-arrow-desa{
    width: 2em;
    margin-top: 4em;
    margin-bottom: 2em;
    position: relative;
    animation-duration: 2s;
    animation-name: aparecer;
    animation-iteration-count: infinite;
}
.img-arrow-desa:hover{
    cursor: pointer;
}

.accordion-container{
    margin-top: 4em;
}

.accordion-item{
    width: 20em;
}

.accordion-description{
    color: black;
    font-size: 1rem;
    font-family: 'Poppins', sans-serif;
}