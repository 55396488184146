.anuncio{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 1em;
    padding: 1em;
  
    
    width: 28em;
}